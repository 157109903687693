import React from 'react';
import * as Sentry from '@sentry/react';
import brandBuild from 'assets/file/brandBuild.json';
import { SentryInfo, SentryLevel, ignorableErrors } from 'utils/sentry';

const brandRelease = `BO_Report_${import.meta.env.MODE.toUpperCase()}@${
  brandBuild.version
}`;

if (
  !import.meta.env.MODE.toLowerCase().includes('local') &&
  !import.meta.env.MODE.toLowerCase().includes('development')
) {
  Sentry.init({
    dsn: 'http://b5a7f37cdd656789000887a78c8ce640@stry.mgc.pw/6',
    release: `${brandRelease}`,
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
      }),
      Sentry.replayIntegration(),
    ],
    tracesSampleRate: 1.0,
    environment: import.meta.env.VITE_SENTRY_ENV || 'LOCAL',
    ignoreErrors: ignorableErrors,
    initialScope: (scope) => {
      scope.setTags({ errorType: 'UNHANDLED ERROR' });
      scope.setLevel(SentryLevel.Fatal);
      scope.setTags(SentryInfo().tags);
      scope.setUser(SentryInfo().user);

      return scope;
    },
  });
}
